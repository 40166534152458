import { createRoot } from 'react-dom/client';

import SpecialistApp from 'apps/specialist';
import { KeycloakService } from 'services/KeycloakService';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';
import { setTokenName } from 'utils/http';

import * as serviceWorker from './serviceWorker';

import 'antd/es/style/css';

setCurrentAppName('specialist');
setTokenName('specialist');
initDatadog('specialist');

const root = createRoot(document.getElementById('root')!);

KeycloakService.initKeycloak('check-sso', () => {
  root.render(<SpecialistApp />);
});

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
